import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import TokenInfo from './TokenInfo';
import Loading from '../common/Loading';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import TextAd from '../sponsorship/TextAd';
import Analytics from '../common/Analytics';

class Token extends React.Component {
	constructor(props) {
		super(props);

		this.handleSelect = this.handleSelect.bind(this);
		const tokenID = this.props.match.params.token_id;

		this.state = {
			key: 'transfers',
            tokenID: tokenID,
            token: {},
            transfers: [],
            holders: [],
			loading: false,
			error: null,
		}
	}

	// React view methods
	componentDidMount() {
		const { tokenID } = this.state;
		this.fetchToken(tokenID);
		Analytics.pageview();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.pathname !== nextProps.location.pathname) {
			const newTokenID = nextProps.match.params.tokenID;
			this.setState({ tokenID: newTokenID });
			this.fetchAccount(newTokenID);
			Analytics.pageview();
		}
	}

	handleSelect(key) {
		switch (key) {
			case 'transfers':
				break;
            case 'holders':
                break;
            default:
				break;
		}
		this.setState({key});
	}

	fetchToken(tokenID) {
		this.setState({ loading: true })

		fetch(`${XRPSCAN_API_URL}/token/${tokenID}`)
			.then(handleResponse)
			.then((data) => {
				this.setState({
					loading: false,
					token: data,
					error: null,
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error.errorMessage,
				});
			});
	}

	render() {
		const {
            token,
			loading,
		} = this.state;

		// render only loading component if loading state is set to true
		if (loading) {
			return <Loading />
		}

		return (
			<div>
				<Helmet>
					<title>{`${token.code}`} - XRPL Token | XRPSCAN</title>
				</Helmet>
				<Row>
					<Col>
						<IntegrationGuide/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<SponsorContainer />
					</Col>
				</Row>
				{/* <Row>
					<Col xs={12} md={12}>
                        <Breadcrumb>
							<LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
							<LinkContainer to={`/tokens`}><Breadcrumb.Item>Tokens</Breadcrumb.Item></LinkContainer>
							<Breadcrumb.Item active className="address-tag address-tag-lg">{token.code}</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
				</Row> */}
				<Row>
					<Col xs={12} md={12}>
						<TokenInfo token={token} />
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<TextAd/>
					</Col>
				</Row>
			</div>
		)
	}
}
export default Token;