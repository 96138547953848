import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Money from '../lib/Money';
import EmptyStateNoRecord from '../lib/EmptyStateNoRecord';
import Loading from '../common/Loading';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import AdvancedTable from '../lib/AdvancedTable';
import AccountTag from '../lib/AccountTag';
import { FormattedNumber } from 'react-intl';
import XBadge from '../lib/XBadge';

const AccountChannel = (props) => {
	const { address } = props;
    const [ paymentChannels, setPaymentChannels ] = useState([]);
	const [ loading, setLoading ] = useState(false);

	const columns = useMemo(
		() => [
			{
				Header: "Payment Channels",
				columns: [
				{
					Header: "#",
					accessor: "idx",
					className: "text-right",
				},
				{
					Header: "Channel",
					accessor: "channel_id",
					Cell: ({ cell: { value }}) => <span className="address-tag address-tag-lga"><Link to={`/object/${value}`}>{value}</Link></span>,
                    className: "hash"
				},
				{
					Header: "Destination",
					accessor: "destination_account",
					Cell: ({ cell: { value }}) => <><AccountTag link>{value}</AccountTag></>,
					className: "text-left",
				},
				{
					Header: "Settle delay (sec)",
					accessor: "settle_delay",
                    Cell: ({ cell: { value }}) => <span className='text-monospace text-muted'><FormattedNumber value={value} /> sec</span>,
					className: "text-right",
				},
				{
					Header: "Amount allocated",
					accessor: "amount",
                    Cell: ({ cell: { value }}) => <Money value={value} drops />,
					className: "text-right"
				},
				{
					Header: "Amount paid",
					accessor: "balance",
                    Cell: ({ cell: { value }}) => <Money value={value} drops />,
					className: "text-right",
				},
				{
					Header: "Balance",
					accessor: "balance_final",
                    Cell: ({ cell: { value }}) => <XBadge variant="primary"><Money value={value} drops min={6} max={6}/></XBadge>,
					className: "text-right",
				},
				],
			},
			],
			[],
	)

    /*
    * Add custom properties to channel list
    */
    const addProperties = useCallback((_channels) => {
        const populatedChannels = _channels.map((channel, idx) => {
            channel.idx = idx + 1;
            channel.balance_final = Number(channel.amount) - Number(channel.balance);
            return channel;
        })
        return populatedChannels;
    }, []);


	useEffect(() => {
		setLoading(true);
		fetch(`${XRPSCAN_API_URL}/account/${address}/channels`)
			.then(handleResponse)
			.then((data) => {
                setPaymentChannels(addProperties(data.channels));
			})
			.catch(() => {
				setPaymentChannels([]);
			})
			.finally(() => {
				setLoading(false);
			});
			return () => {
				setPaymentChannels([]);
			}
	}, [address, addProperties]);

	if (loading) {
		return <Loading/>;
	}

	if (!loading && paymentChannels && paymentChannels.length === 0) {
		return (
			<EmptyStateNoRecord title="No Payment Channels">
				This account has no Payment Channels
			</EmptyStateNoRecord>
		)
	} else {
		return (
            <>
                <AdvancedTable
                    columns={columns}
                    data={paymentChannels}
                    filterProperty='destination_account'
                    filterPlaceholder="Search by destination account"
                />
            </>
        )
	}
}

AccountChannel.propTypes = {
	address: PropTypes.string.isRequired,
}

export default AccountChannel;