import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import CardTitleContainer from '../lib/CardTitleContainer';
import AccountIcon from '../lib/AccountIcon';
import AccountTag from '../lib/AccountTag';
import { FormattedNumber } from 'react-intl';
import VerifiedBadge from '../lib/VerifiedBadge';
import Money from '../lib/Money';
import XIcon from '../lib/XIcon';

const TokenInfo = (props) => {
	const { token } = props;

	return (
		<Card className="shadow-sm">
			<Card.Body>
				<Card.Title>
					<CardTitleContainer icon="square-binary" title="Token summary" />
				</Card.Title>
				<Row>
					<Col xs={12} md={5}>
						<Table responsive>
							<thead>
								{token?.meta?.token?.name &&
								<tr>
									<th className="data-header">Name</th>
									<th>
										<span className='text-monospace'>
											<Link to={`/token/${token.token}`}>{token?.meta?.token?.name}</Link>
										</span>
										{token?.IssuingAccount?.verified &&
											<VerifiedBadge />
										}
									</th>
								</tr>
								}
							</thead>

							<tbody>
								{token?.code &&
								<tr>
									<td className="data-header text-nowrap">Token code</td>
									<td>
										<span className='text-monospace'>
											<Link to={`/token/${token.token}`}>${token.code}</Link>
										</span>
									</td>
								</tr>
								}
								{token.issuer &&
								<tr>
									<td>Issuer</td>
									<td>
										<AccountTag name={token.IssuingAccount} link>{token.issuer}</AccountTag>
									</td>
								</tr>
								}
								{token.currency && token.currency !== token.code &&
								<tr>
									<td className='text-nowrap'>Hex code</td>
									<td>
										<span className='text-monospace text-muted'>
											{token.currency}
										</span>
									</td>
								</tr>
								}
								{token?.meta?.token?.description &&
								<tr>
									<td colSpan={2}>
										<p className='text-muted'>
											{token?.meta?.token?.description}
										</p>
									</td>
								</tr>
								}
							</tbody>
						</Table>
					</Col>

					<Col xs={12} md={3}>
						<Table responsive>
							<thead>
								<tr>
									<th colSpan="2">Metrics</th>
								</tr>
							</thead>
							<tbody>
								{token?.holders &&
								<tr>
									<td>Holders</td>
									<td className='text-monospace text-right'>
										<FormattedNumber value={token?.holders}	/>
									</td>
								</tr>
								}

								{token?.metrics?.trustlines &&
								<tr>
									<td>Trustlines</td>
									<td className='text-monospace text-right'>
										<FormattedNumber value={token?.metrics?.trustlines} />
									</td>
								</tr>
								}
								{token?.amms > 0 &&
								<tr>
									<td className='text-nowrap'>In AMMs</td>
									<td className='text-monospace text-right'>
										<FormattedNumber value={token?.amms} />
									</td>
								</tr>
								}
								{token?.metrics?.volume_24h &&
								<tr>
									<td className='text-nowrap'>Volume (24h)</td>
									<td className='text-monospace text-right'>
										<Money value={token?.metrics?.volume_24h} max={2}/>
									</td>
								</tr>
								}
							</tbody>
						</Table>
					</Col>

					<Col xs={12} md={4}>
						<Table responsive className='mb-0'>
							<thead>
								{token &&
								<tr className="table-secondary">
									<td colSpan="1" className="text-center">
										<AccountIcon
											twitter={token?.IssuingAccount?.twitter}
											emailHash={token?.EmailHash}
											rounded={true}
											roundedCircle={false}
											height={113}
											width={113}
										/>
									</td>
								</tr>
								}
							</thead>
						</Table>
						<Table responsive>
							<tbody>
								{(token?.IssuingAccount?.domain || token?.IssuingAccount?.twitter) &&
								<tr>
									<td colSpan={2} className='text-center'>
										{token?.IssuingAccount?.domain &&
										<span className='mr-3'>
											<a href={`https://${token?.IssuingAccount?.domain}`} target="_blank" rel="noopener noreferrer nofollow">
												<XIcon icon="globe-pointer" faStyle="fad" size="lg" />
											</a>
										</span>
										}
										{token?.IssuingAccount?.twitter &&
										<span className='mr-3'>
											<a href={`https://x.com/${token?.IssuingAccount?.twitter}`} target="_blank" rel="noopener noreferrer nofollow">
												<XIcon icon="x-twitter" faStyle="fab" size="lg" />
											</a>
										</span>
										}
									</td>
								</tr>
								}

								{token?.price >= 0 &&
								<tr className='table-'>
									<td>Price</td>
									<td className='text-right'>
										<span className='text-monospace'>
											<Money value={token?.price} />
										</span>
									</td>
								</tr>
								}
								{token?.marketcap >= 0 && 
								<tr className='table-'>
									<td>Marketcap</td>
									<td className='text-right'>
										<span className='text-monospace'>
											<Money value={token?.marketcap} max={2}/>
										</span>
									</td>
								</tr>
								}
								{token?.supply >= 0 &&
								<tr className='table-success'>
									<td>Supply</td>
									<td className='text-right'>
										<span className='text-monospace'>
											<Money value={token?.supply} currency={token?.currency} issuer={token?.issuer}/>
										</span>
									</td>
								</tr>
								}
							</tbody>
						</Table>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
}


TokenInfo.propTypes = {
	account: PropTypes.object.isRequired,
	addressParam: PropTypes.string,
	error: PropTypes.object,	
}

export default TokenInfo;
