import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import AmendmentInfo from './AmendmentInfo';
import AmendmentVotes from './AmendmentVotes';
import Loading from '../common/Loading';
import Analytics from '../common/Analytics';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import TextAd from '../sponsorship/TextAd';


class Amendment extends React.Component {
  constructor() {
    super();

    this.state = {
      amendment: {},
      loading: false,
      error: null,
    }
  }

  componentDidMount() {
    const amendment_id = this.props.match.params.amendment_id;
    this.fetchAmendment(amendment_id);
    Analytics.pageview();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      const new_amendment_id = nextProps.match.params.amendment_id;
      this.fetchAmendment(new_amendment_id);
      Analytics.pageview();
    }
  }

  fetchAmendment(amendment_id) {
    this.setState({ loading: true })
    fetch(`${XRPSCAN_API_URL}/amendment/${amendment_id}`)
      .then(handleResponse)
      .then((data) => {
        this.setState({
          amendment: data,
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.errorMessage,
        });
      });
  }


  render() {
    const {
      amendment,
      loading,
      error,
    } = this.state;

    // render only loading component if loading state is set to true
    if (loading) {
      return <Loading />
    }

    return (
      <div>
        <Helmet>
          <title>{`${amendment.name || amendment.amendment_id || 'Not found'}`} - XRP Ledger Amendment | XRPSCAN</title>
        </Helmet>
				<Row>
					<Col>
						<IntegrationGuide/>
					</Col>
				</Row>
				<Row>
          <Col xs={12} md={12}>
						<SponsorContainer />
					</Col>
				</Row>
        {/* <Row>
					<Col xs={12} md={12}>
            <Breadcrumb>
              <LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
              <LinkContainer to={`/amendments`}><Breadcrumb.Item>Amendments</Breadcrumb.Item></LinkContainer>
              <Breadcrumb.Item active>{amendment.name || amendment.amendment_id}</Breadcrumb.Item>
            </Breadcrumb>
					</Col>
				</Row> */}
        <Row>
          <Col xs={12} md={12}>
            <AmendmentInfo amendment={amendment} error={error} />
          </Col>
        </Row>
				<Row>
					<Col xs={12} md={12}>
						<TextAd/>
					</Col>
				</Row>
        { !amendment.enabled && amendment.voters && amendment.vetoers &&
        <Row>
          <Col xs={12} md={12}>
            <AmendmentVotes amendment={amendment} error={error} />
          </Col>
        </Row>
        }
      </div>
    );    
    
  }
}

export default Amendment;