import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import { LinkContainer } from 'react-router-bootstrap';
// import { FormattedNumber } from 'react-intl';
import { Helmet } from 'react-helmet';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import LedgerInfo from './LedgerInfo';
import LedgerTransaction from './LedgerTransaction';
import Loading from '../common/Loading';
import Analytics from '../common/Analytics';
import XIcon from '../lib/XIcon';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import TextAd from '../sponsorship/TextAd';


class Ledger extends React.Component {
	constructor() {
		super();

		this.state = {
			ledger: {},
			transactions: [],
			loading: false,
			error: null,
		}
	}

	componentDidMount() {
		const ledger_index = this.props.match.params.ledger_index;
		this.fetchLedger(ledger_index);
		this.fetchTransactions(ledger_index);
		Analytics.pageview();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.pathname !== nextProps.location.pathname) {
			const new_ledger_index = nextProps.match.params.ledger_index;
			this.fetchLedger(new_ledger_index);
			this.fetchTransactions(new_ledger_index);
			Analytics.pageview();
		}
	}

	fetchLedger(ledger_index) {
		this.setState({ loading: true });

		fetch(`${XRPSCAN_API_URL}/ledger/${ledger_index}`)
			.then(handleResponse)
			.then((data) => {
				this.setState({
					loading: false,
					ledger: data,
					error: null,
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error.errorMessage,
				});
			});
	}

	fetchTransactions(ledger_index) {
		fetch(`${XRPSCAN_API_URL}/ledger/${ledger_index}/transactions`)
			.then(handleResponse)
			.then((data) => {
				this.setState({
					transactions: data,
					error: null,
				});
			})
			.catch((error) => {
				this.setState({
					error: error.errorMessage,
				});
			});
	}

	getTotalFee() {
		const { transactions } = this.state;
		return transactions.reduce((accumulator, tx) => {
			if (Number(tx.Fee)) {
				return accumulator + Number(tx.Fee);
			}
			else {
				return accumulator;
			}
		}, 0);
	}

	render() {
		const {
			ledger,
			transactions,
			loading,
			error,
		} = this.state;

		// Render only loading component if loading state is set to true
		if (loading) {
			return <Loading />
		}

		return (
			<div>
				<Helmet>
					<title>{`${ledger.ledger_index || 'Not found'}`} - XRP Ledger Index | XRPSCAN</title>
				</Helmet>
				<Row>
					<Col>
						<IntegrationGuide/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<SponsorContainer />
					</Col>
				</Row>
				{/* <Row>
					<Col xs={12} md={12}>
						<Breadcrumb>
							<LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
							{ledger.ledger_index &&
							<Breadcrumb.Item active><FormattedNumber value={ledger.ledger_index} /></Breadcrumb.Item>
							}
						</Breadcrumb>
					</Col>
				</Row> */}
				<Row>
					<Col xs={12} md={12}>
						<LedgerInfo ledger={ledger} error={error} totalFee={this.getTotalFee()}/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<TextAd/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<Card>
							<Card.Body>
								<Card.Title>
									<XIcon icon='file-invoice' />
									<span className="pl-2">Transactions</span>
								</Card.Title>
								<LedgerTransaction transactions={transactions} />
							</Card.Body>
							<Card.Footer>{ledger.tx_count || 0} transactions included in this ledger</Card.Footer>
						</Card>
					</Col>
				</Row>
			</div>
		);		
	}
}

export default Ledger;
