import React from 'react';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import { LinkContainer } from 'react-router-bootstrap';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse,currencyName } from '../common/Helpers';
import XBadge from '../lib/XBadge';
import Loading from '../common/Loading';
import CardTitleContainer from '../lib/CardTitleContainer';
import Analytics from '../common/Analytics';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import AccountTag from '../lib/AccountTag';
import AccountIcon from '../lib/AccountIcon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class TokenIndex extends React.Component {
	constructor() {
		super();

		this.state = {
            tokens: [],
			loading: false,
			error: null,
		}
		this.fetchTokens = this.fetchTokens.bind(this);
	}

	componentDidMount() {
        this.fetchTokens();
        Analytics.pageview();
	}

	fetchTokens() {
		this.setState({ loading: true })
		fetch(`${XRPSCAN_API_URL}/tokens`)
			.then(handleResponse)
			.then((data) => {
				this.setState({
					tokens: data || [],
					loading: false,
					error: null,
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error.errorMessage,
				});
			});
	}

	render() {
		const {
            tokens,
            loading,
		} = this.state;

		// render only loading component if loading state is set to true
		if (loading) {
			return <Loading />
		}

		return (
			<div>
				<Helmet>
					<title>XRP Tokens directory | XRPSCAN</title>
				</Helmet>
				<Row>
					<Col>
						<IntegrationGuide/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<SponsorContainer />
					</Col>
				</Row>
				{/* <Row>
					<Col xs={12} md={12}>
						<Breadcrumb>
							<LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
							<Breadcrumb.Item active>Tokens</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
				</Row> */}

				{/* <Row>
				  <Col xs={12} md={12}>
				    <TextAd/>
					</Col>
				</Row> */}

				<Card>
					<Card.Body>
						<Card.Title>
							<CardTitleContainer icon="server" title="XRPL Tokens directory" />
						</Card.Title>
						<Table responsive>
							<thead>
								<tr>
									<th className="text-right">#</th>
									<th className="text-left">Token</th>
									<th className="text-left">Issuer</th>
									<th className="text-right">Holders</th>
									<th className="text-right">AMMs</th>
									<th className="text-right">Issued supply</th>
									<th className="text-right">Price</th>
									<th className="text-right">Market Cap.</th>
								</tr>
							</thead>
							<tbody>
								{ tokens.map((token, index) => (
								<tr key={token.id}>
									<td className="text-right"><span>{index+1}</span></td>
									<td className="text-left">
										<Link to={`/token/${encodeURI(token?.token)}`}>
											<XBadge className="text-monospace">${currencyName(token.code)}</XBadge>
										</Link>
                                    </td>
									<td className="text-left text-nowrap">
										<Link to={`/token/${encodeURI(token?.token)}`}>
											<AccountIcon twitter={token?.IssuingAccount?.twitter} />
											<AccountTag name={token?.IssuingAccount} link={false}>{token.issuer}</AccountTag>
										</Link>
									</td>
									<td className="text-right">
										<span className="text-monospace">
											<FormattedNumber value={token?.metrics?.holders}/>
										</span>
									</td>
									<td className="text-right">
										<span className="text-monospace">
											<FormattedNumber value={token.amms}/>
										</span>
									</td>
									<td className="text-right text-muted">
										{token?.supply >= 0 &&
										<span className="text-monospace">
											<FormattedNumber value={token?.supply} maximumFractionDigits={2}/>
										</span>
										}
									</td>
									<td className="text-right text-muted">
										{token?.price >= 0 &&
										<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{token?.price} XRP</Tooltip>} placement='bottom'>
											<span className="text-monospace">
												<FormattedNumber value={token?.price} maximumFractionDigits={6} minimumFractionDigits={2}/>
											</span>
										</OverlayTrigger>
										}
									</td>
									<td className="text-right">
										{token?.marketcap >= 0 &&
										<span className="text-monospace">
											<XBadge variant="success " className="text-monospace">
											<FormattedNumber value={token?.marketcap} maximumFractionDigits={0}/>
											</XBadge>
										</span>
										}
									</td>
								</tr>	
								))}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</div>
		);		
	}
}

export default TokenIndex;