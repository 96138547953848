import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Search from './Search';
import './Header.css';
import XIcon from '../lib/XIcon';

const Header = () => {
	return (
		<header className="Header">
			<Container>
			  <Navbar variant="dark" className="justify-content-between">
			    <Navbar.Brand>
					<span className='d-inline-block align-middle text-light link-plain'>
						<Link to="/">
							<XIcon className="text-light" icon="sun-bright" faStyle="fasr" size="lg" />
							<span className='text-light ml-2 font-weight-bold'>XRPSCAN</span>
						</Link>
					</span>
			    </Navbar.Brand>
			    <Nav className="justify-content-end" activeKey="/amendments">
						<Nav.Item><Link to="/tokens" className="nav-link active">Tokens</Link></Nav.Item>
						<Nav.Item><Link to="/amms" className="nav-link active">AMMs</Link></Nav.Item>
						<Nav.Item><Link to="/metrics" className="nav-link active">Metrics</Link></Nav.Item>
						<Nav.Item><Link to="/validators" className="nav-link active">Validators</Link></Nav.Item>
						<Nav.Item><Link to="/amendments" className="nav-link active">Amendments</Link></Nav.Item>
						<Nav.Item className="d-none d-md-block">
							<a href="https://console.xrpscan.com" className="nav-link active" target="_blank" rel="noopener noreferrer">Search</a>
						</Nav.Item>
			    </Nav>
			    <Search/>
			  </Navbar>
		  </Container>
	  </header>
	);
}

export default Header;