import React from 'react';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import { LinkContainer } from 'react-router-bootstrap';
import { FormattedNumber, FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { XRPSCAN_API_URL, XRPSCAN_WSS_URL } from '../../config';
import { handleResponse, getAmendmentVoteStyle } from '../common/Helpers';
import { XRPLFlagInterval } from '../common/Helpers';
import Loading from '../common/Loading';
import XIcon from '../lib/XIcon';
import AmendmentProgress from './AmendmentProgress';
import AmendmentETA from './AmendmentETA';
import socketio from 'socket.io-client';
import Analytics from '../common/Analytics';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import TextAd from '../sponsorship/TextAd';
import XBadge from '../lib/XBadge';


class AmendmentIndex extends React.Component {
  constructor() {
    super();
    const socket = socketio(XRPSCAN_WSS_URL, {
      autoConnect: false,
      // transports: ["polling"]
    });

    this.state = {
      socket: socket,
      amendments: [],
      vm: null,
      highestLedger: 32570,
      loading: false,
      error: null,
    }
  }

  componentDidMount() {
    this.fetchAmendments();
    if (this.state.socket.disconnected) {
      this.state.socket.connect();
    }
    this.state.socket.on('vm:new', (vm) => this.updateValidationMessages(vm));
    Analytics.pageview();
  }

  componentWillUnmount() {
    if (this.state.socket && this.state.socket.connected) {
      this.state.socket.disconnect();
    }
  }

  fetchAmendments() {
    this.setState({ loading: true })
    fetch(`${XRPSCAN_API_URL}/amendments`)
      .then(handleResponse)
      .then((data) => {
        this.setState({
          amendments: data,
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.errorMessage,
        });
      });
  }

  updateValidationMessages(vm) {
    if (vm.ledger_index > this.state.highestLedger) {
      this.setState({
        highestLedger: vm.ledger_index,
      })
      // If this is the Voting ledger, fetch new amendment vote status
      if (Number(vm.ledger_index) % XRPLFlagInterval === 1) {
        this.fetchAmendments();
      }
    }
    this.setState({ vm });
  }

  render() {
    const {
      amendments,
      vm,
      highestLedger,
      loading,
    } = this.state;

    // render only loading component if loading state is set to true
    if (loading) {
      return <Loading />
    }

    return (
      <div>
        <Helmet>
          <title>XRP Ledger Amendments registry | XRPSCAN</title>
        </Helmet>
				<Row>
					<Col>
						<IntegrationGuide/>
					</Col>
				</Row>
				<Row>
          <Col xs={12} md={12}>
						<SponsorContainer />
					</Col>
				</Row>
        {/* <Row>
					<Col xs={12} md={12}>
            <Breadcrumb>
              <LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
              <Breadcrumb.Item active>Amendments</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
				</Row> */}

        <AmendmentProgress vm={vm} highestLedger={highestLedger} />

				<Row>
					<Col xs={12} md={12}>
						<TextAd/>
					</Col>
				</Row>

        <Card>
          <Card.Body>
            <Card.Title>
              <XIcon icon='cogs' />
              <span className="pl-2">Amendments registry</span>
            </Card.Title>
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Version</th>
                  <th>Amendment ID</th>
                  <th>Name</th>
                  <th className="text-center">Voters</th>
                  <th className="text-center">Threshold</th>
                  <th className="text-center">Consensus</th>
                  <th className="text-center">Enabled</th>
                  <th className="text-right">On (Tx)</th>
                </tr>
              </thead>
              <tbody>
                { amendments.map((amendment, index) => (
                <tr
                  key={amendment.amendment_id}
                  className={ amendment.enabled ? '' : getAmendmentVoteStyle(amendment.count, amendment.threshold) }
                >
                  <td><span>{index+1}</span></td>
                  <td>
                    { amendment.introduced ? `v${amendment.introduced}` : 'TBD' }
                  </td>
                  <td>
                    <span className='address-tag'>
                      <Link to={`/amendment/${amendment.name}`}>
                        {amendment.amendment_id}
                      </Link>
                    </span>
                  </td>
                  <td className="text-nowrap">
                    <Link to={`/amendment/${amendment.name}`}>
                      {amendment.name}
                    </Link>
                    { amendment.deprecated &&
                      <XBadge variant="dark" className="ml-1">DEPRECATED</XBadge>
                    }
                  </td>
                  <td className="text-center"><span className="hash">{amendment.count}</span></td>
                  <td className="text-center">
                    {amendment.threshold &&
                      <span className="hash">{amendment.threshold}/{amendment.validations}</span>
                    }
                  </td>
                  <td className="text-center">{amendment.count &&
                    <span className="hash"><FormattedNumber value={(amendment.count/amendment.validations) * 100} maximumFractionDigits={2} />%</span>
                    }
                  </td>
                  <td className="text-center">
                    { amendment.enabled ?
                      <Badge variant="success">YES</Badge> :
                      <Badge variant="danger">&nbsp;NO&nbsp;</Badge>
                    }
                  </td>
                  <td className="text-right text-nowrap">
                    {amendment.enabled_on &&
                    <span>
                      <Link to={`/tx/${amendment.tx_hash}`}>
                        <FormattedDate value={amendment.enabled_on} timeZone="UTC" year='numeric' month='2-digit' day='2-digit'/>
                      </Link>
                    </span>
                    }
                    {!amendment.enabled_on && amendment.majority && 
                    <span>
                      <XBadge variant="success" className="text-monospace text-nowrap">
                        ETA: <AmendmentETA majority={amendment.majority} />
                      </XBadge>
                    </span>
                    }
                    {!amendment.enabled_on && !amendment.majority &&
                    <span>
                      <Badge variant="warning">&nbsp;VOTING&nbsp;</Badge>&nbsp;
                    </span>
                    }
                  </td>
                </tr>               
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    );    
  }
}

export default AmendmentIndex;