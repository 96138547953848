import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Badge from 'react-bootstrap/Badge';

// const excludedCountries = [
//   "IR",
// ];

const buttonVariations = [
  {
    label: <>Buy XRP &amp; RLUSD</>,
    title: <>Buy XRP &amp; RLUSD instantly on Uphold</>,
    content: <>
        Buy XRP and RLUSD seamlessly on Uphold - all-in-one crypto platform. In the US? Earn rewards on RLUSD. Terms Apply. Capital at risk.
    </>,
    href: "https://api.xrpscan.com/api/v1/campaign/upholdbutton1",
  }
]
let variantIndex = 0;
try {
  variantIndex = Math.floor((Math.random()*10)) % buttonVariations.length;
} catch (error) {}
const buttonVariation = buttonVariations[variantIndex];

const UpholdButton = (props) => {
  // const { location } = props;
  // if (!location || excludedCountries.includes(location)) {
  //   return <></>
  // }

  const popover = (
    <Popover id="popover-sponsor">
      <Popover.Title>
        {buttonVariation.title}
        <span className="small ml-1"><Badge variant="secondary">SPONSORED</Badge></span>
      </Popover.Title>
      <Popover.Content>{buttonVariation.content}</Popover.Content>
    </Popover>
  );

  return (
    <span>
        <OverlayTrigger
          key={'bottom'}
          placement={'bottom'}
          overlay={popover}
        >
          <Button
            variant="info"
            size="sm"
            href={buttonVariation.href}
            target="_blank"
            rel="noopener nofollow"
            className="dropdown-toggle"
          >{buttonVariation.label}</Button>
        </OverlayTrigger>
    </span>
  )
}

UpholdButton.propTypes = {
	location: PropTypes.string.isRequired,
}

export default UpholdButton;