import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import Home from './components/home/Home'
import Account from './components/account/Account'
import Activate from './components/account/Activate'
import Transaction from './components/transaction/Transaction'
import NFToken from './components/nftoken/NFToken'
import TokenIndex from './components/token/TokenIndex'
import Token from './components/token/Token'
import Ledger from './components/ledger/Ledger'
import Node from './components/node/Node'
import ValidatorIndex from './components/validator/ValidatorIndex'
import Validator from './components/validator/Validator'
import AmendmentIndex from './components/amendment/AmendmentIndex'
import Amendment from './components/amendment/Amendment'
import AMMIndex from './components/amm/AMMIndex'
import LedgerObject from './components/object/LedgerObject';
import Metric from './components/metric/Metric'
import Balance from './components/balance/Balance'
import AdvancedSearch from './components/search/AdvancedSearch';
import NotFound from './components/notfound/NotFound'
import ScrollToTop from './components/common/ScrollToTop'
import ReactGA from 'react-ga4';
import Analytics from './components/common/Analytics';
import { GA_TRACKING_ID } from './config';

import './components/common/FontAwesome';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-grid.css';
import 'bootstrap/dist/css/bootstrap-reboot.css';
import './App.css';


ReactGA.initialize(GA_TRACKING_ID);
Analytics.event({category: 'Application', action: 'Application loaded', label: 'App-load'});

class App extends Component {
  render(){
    return (
      <BrowserRouter>
        <div>
          <ScrollToTop>
          <Header />
            <div className="App">
              <Container>
                <Switch>
                  <Route path="/" component={Home} exact />
                  <Route path="/account/:address" component={Account} exact />
                  <Route path="/tx/:hash" component={Transaction} exact />
                  <Route path="/transaction/:hash" component={Transaction} exact />
                  <Route path="/nft/:nftoken_id" component={NFToken} exact />
                  <Route path="/ledger/:ledger_index" component={Ledger} exact />
                  <Route path="/node/:node_public_key" component={Node} exact />
                  <Route path="/validators" component={ValidatorIndex} exact />
                  <Route path="/validator/:validation_public_key" component={Validator} exact />
                  <Route path="/tokens" component={TokenIndex} exact />
                  <Route path="/token/:token_id" component={Token} exact />
                  <Route path="/amendments" component={AmendmentIndex} exact />
                  <Route path="/amendment/:amendment_id" component={Amendment} exact />
                  <Route path="/amms" component={AMMIndex} exact />
                  <Route path="/object/:index" component={LedgerObject} exact />
                  <Route path="/metrics" component={Metric} exact />
                  <Route path="/balances" component={Balance} exact />
                  <Route path="/search" component={AdvancedSearch} exact />
                  <Route path="/activate/:address" component={Activate} exact />
                  <Route component={NotFound} />
                </Switch>
              </Container>
            </div>
          </ScrollToTop>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;