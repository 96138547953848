import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Money from '../lib/Money';
import XBadge from '../lib/XBadge';
import { currencyName } from '../common/Helpers';
import EmptyStateNoRecord from '../lib/EmptyStateNoRecord';


const AccountObligation = (props) => {
	const { obligations, account } = props;

	return (
		<div>
			<Table responsive>
				<thead>
					<tr>
						<th className="text-center">#</th>
						<th className="text-center">Type</th>
						<th className="text-left">Token name</th>
						<th className="text-left">Token code</th>
						<th className="text-right">Issued supply</th>
					</tr>
				</thead>
				<tbody>
					{ obligations.map((obligation, index) =>
					<tr key={index}>
						<td className="text-center">{index+1}</td>
						<td className="text-center">
							<XBadge variant="success">ISSUED TOKEN</XBadge>
						</td>
						<td className="text-left text-monospace">{currencyName(obligation.currency)}</td>
						<td className="text-left text-monospace">{obligation.currency}</td>
						<td className="text-right">
							<Money
								value={obligation.value}
								currency={obligation.currency}
                issuer={account.account}
							/>
						</td>
					</tr>
					)}
				</tbody>
			</Table>
			{obligations && obligations.length === 0 &&
				<EmptyStateNoRecord title="No issued tokens">
					This account has not issued any tokens.
				</EmptyStateNoRecord>
			}      
		</div>
	);
}

AccountObligation.propTypes = {
	obligations: PropTypes.array.isRequired,
	account: PropTypes.object.isRequired,
}
export default AccountObligation;