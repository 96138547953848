import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import { LinkContainer } from 'react-router-bootstrap';
import { XRPSCAN_API_URL } from '../../config';
import Analytics from '../common/Analytics';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import XIcon from '../lib/XIcon';
import Callout from '../lib/Callout';
import { Table } from 'react-bootstrap';
import AccountTag from '../lib/AccountTag';
import XBadge from '../lib/XBadge';

const DESTINATION_TAG = 58525053; // string "XRPS" in hex, used for on-chain analytics
const NEBEUS_IFRAME_URL = "https://crypto-widget.nebeus.com";

const Activate = (props) => {
    const account = props?.match?.params?.address;
    Analytics.event({category: 'AccountActivation', action: 'AccountActivation', label: account});

    const getMessageSignature = async () => {
        const response = await fetch(`${XRPSCAN_API_URL}/account/${account}/signature-nebeus?dt=${DESTINATION_TAG}`);
        const data = await response.json();
        return data.signature;
    }

    const handlePostMessage = async (event) => {
        if (event.origin !== NEBEUS_IFRAME_URL) return;
        if (event.data && event.data.type) {
            switch (event.data.type) {
                case "SETTINGS_APPLIED": {
                    console.log('Widget loaded: ', event.data.payload.settings);
                    break;
                }
                case "WIDGET_INITIATED": {
                    const messagePayload = {
                        onlyPredefinedWallets: true,
                        wallets: [
                            { 
                                address: `${account}?dt=${DESTINATION_TAG}`,
                                currencyCode: 'XRP'
                            },
                        ],
                    }
                    const messageSignature = await getMessageSignature(messagePayload);

                    event.source.postMessage({
                        type: "APPLY_SETTINGS",
                        payload: messagePayload,
                        signature: messageSignature,
                    }, event.origin);
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    useEffect(() => {
        window.addEventListener("message", handlePostMessage);
        Analytics.pageview();
        return () => {
            window.removeEventListener("message", handlePostMessage);
        }
    });

	return (
		<div>
				<Helmet>
					<title>Activate account | XRPSCAN</title>
				</Helmet>
				<Row>
					<Col>
						<IntegrationGuide/>
					</Col>
				</Row>
				<Row>
                    <Col xs={12} md={12}>
						<SponsorContainer />
					</Col>
				</Row>
				{/* <Row>
					<Col xs={12} md={12}>
						<Breadcrumb>
							<LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
							<Breadcrumb.Item active>Account activation</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
				</Row> */}
				<Row>
					<Col xs={12} md={12}>
						<Card>
							<Card.Body>
								<Card.Title>
									<XIcon icon='circle-dollar-to-slot' />
									<span className="pl-2">Account activation</span>
								</Card.Title>

                                <Row>
                                    <Col xs={12} md={5}>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th className="data-header">Address</th>
                                                <th>
                                                    <AccountTag link>{account}</AccountTag>
                                                    <XBadge variant="warning" className="ml-2">INACTIVE</XBadge>
                                                </th>
                                                </tr>
                                        </thead>
                                    </Table>
                                    </Col>
                                    <Col xs={12} md={7}>
                                        <Callout variant="warning"><span className='ml-2'>This account is inactive. Activate it below or send 10 XRP to this address to activate the account.</span></Callout>
                                    </Col>
                                </Row>

                                <Callout title="TERMS OF SERVICE" variant="primary"><span className='ml-2'>Account activation service is provided in partnership with Nebeus. For support requests related to account activation, please contact <a href="https://support.nebeus.com/portal/en/newticket" target="_blank" rel="noopener noreferrer">Nebeus support</a>.</span></Callout>
                                <iframe
                                    title="Activate account"
                                    id="nebeusCryptoWidget"
                                    src={NEBEUS_IFRAME_URL}
                                    height="600"
                                    width="100%"
                                ></iframe>
							</Card.Body>
						</Card>
					</Col>
				</Row>

		</div>
	);
}

export default Activate;