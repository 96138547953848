import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Badge from 'react-bootstrap/Badge';

const excludedCountries = [
  "US",
  "RU",
  "AF",
  "CU",
  "ER",
  "IR",
  "IQ",
  "KP",
  "LY",
  "MM",
  "SD",
  "SO",
  "SY",
  "YE",
];

const buttonVariations = [
  {
    label: <>Get Free Crypto Card</>,
    title: <>Nebeus Mastercard – Switch between 25+ cryptocurrencies</>,
    content: <>
    <ul>
      <li>No need to convert crypto in advance, auto-converts as you spend </li>
      <li>Withdraw your crypto gains at ATMs: up to 2,000 EUR, USD, or GBP daily</li>
      <li>Free physical and virtual cards when paying only with crypto</li>
    </ul></>,
    href: "https://api.xrpscan.com/api/v1/campaign/nebeusbutton1",
  },
  {
    label: <>Get XRP Loan: 70% LTV</>,
    title: <>Nebeus – Discover the broadest loan offer ever</>,
    content: <>
    <ul>
      <li>Flexible loan terms: 1 day to 36 months</li>
      <li>Interest Rate from 10.5% to 14.5%</li>
      <li>Early repayment options</li>
      <li>Borrow up to 250,000 USDC</li>
      <li>Use loan funds with your Nebeus card</li>
    </ul></>,
    href: "https://api.xrpscan.com/api/v1/campaign/nebeusbutton2",
  },
]
let variantIndex = 0;
try {
  variantIndex = Math.floor((Math.random()*10)) % buttonVariations.length;
} catch (error) {}
const buttonVariation = buttonVariations[variantIndex];

const NebeusButton = (props) => {
  const { location } = props;
  if (!location || excludedCountries.includes(location)) {
    return <></>
  }

  const popover = (
    <Popover id="popover-sponsor">
      <Popover.Title>
        {buttonVariation.title}
        <span className="small ml-1"><Badge variant="secondary">SPONSORED</Badge></span>
      </Popover.Title>
      <Popover.Content>{buttonVariation.content}</Popover.Content>
    </Popover>
  );

  return (
    <span>
        <OverlayTrigger
          key={'bottom'}
          placement={'bottom'}
          overlay={popover}
        >
          <Button
            variant="info"
            size="sm"
            href={buttonVariation.href}
            target="_blank"
            rel="noopener nofollow"
            className="dropdown-toggle"
          >{buttonVariation.label}</Button>
        </OverlayTrigger>
    </span>
  )
}

NebeusButton.propTypes = {
	location: PropTypes.string.isRequired,
}

export default NebeusButton;