import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Money from '../lib/Money';
import AccountTag from '../lib/AccountTag';
import XBadge from '../lib/XBadge';
import EmptyStateNoRecord from '../lib/EmptyStateNoRecord';
import { currencyName } from '../common/Helpers';
import AccountIcon from '../lib/AccountIcon';

const AccountAsset = (props) => {
	const { assets } = props;

	return (
		<div>
			<Table responsive>
				<thead>
					<tr>
						<th className="text-center">#</th>
						<th className="text-center">Type</th>
						<th className="text-left">Token name</th>
						<th className="text-center"></th>
						<th className="text-left">Issuer</th>
						<th className="text-left">Token code</th>
						<th className="text-right">Balance</th>
					</tr>
				</thead>
				<tbody>
					{ assets.map((asset, index) =>
					<tr key={index}>
						<td className="text-center">{index+1}</td>
						<td className="text-center">
							<XBadge variant="info">TOKEN</XBadge>
						</td>
						<td className="text-left text-monospace">{currencyName(asset.currency)}</td>
						<td className="text-left">
							<AccountIcon twitter={asset?.counterpartyName?.twitter} />
						</td>
						<td className="text-left">
							<AccountTag name={asset.counterpartyName} link>{asset.counterparty}</AccountTag>
						</td>
						<td className="text-left text-monospace">{asset.currency}</td>
						<td className="text-right">
							<Money
								value={asset.value}
								currency={asset.currency}
								issuer={asset.counterparty}
							/>
						</td>
					</tr>
					)}
				</tbody>
			</Table>
			{assets && assets.length === 0 &&
				<EmptyStateNoRecord title="No tokens">
					This account does not own any tokens.
				</EmptyStateNoRecord>
			}
		</div>
	);
}

AccountAsset.propTypes = {
	assets: PropTypes.array.isRequired,
}
export default AccountAsset;