import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { FormattedDate, FormattedNumber } from 'react-intl';
import LedgerTag from '../lib/LedgerTag';
import Money from '../lib/Money';
import XIcon from '../lib/XIcon';
import Callout from '../lib/Callout';
import CardTitleContainer from '../lib/CardTitleContainer';


const LedgerInfo = (props) => {
	const {
		ledger,
		error,
		totalFee,
	} = props;

	if (error) {
		return <div className="error">{error}</div>
	}

	return (
	<div>
		{ ledger.ledger_index === 32570 &&
		<Row>
			<Col xs={12} md="12">
				<Callout title="LEDGER #32,570" variant='warning'>
				&nbsp; Due to a mishap early in the XRP Ledger's history, ledgers <strong>#1</strong> through
				<strong> #32,569</strong> were lost. (This loss represents approximately the first week of
				ledger history.) Thus, ledger <strong>#32,570</strong> is the earliest ledger available
				anywhere. Because the XRP Ledger's state is recorded in every ledger
				version, the ledger can continue without the missing history. A ledger
				index reset to <strong>#0</strong> was deemed too disruptive, therefore <strong>#32,570</strong> may be thought
				of as the <LedgerTag ledger_index={32570} /> ledger.
				&nbsp;<a href="https://xrpl.org/docs/concepts/consensus-protocol#footnotes" rel="nofollow noopener noreferrer" target="_blank">Read more &rarr;</a>
				</Callout>
			</Col>
		</Row>
		}
		{ ledger.tag && ledger.tag === 'flare_snapshot' &&
		<Row>
			<Col xs={12} md="12">
				<Callout title="FLARE SNAPSHOT" variant='success'>
				&nbsp; This ledger, the first one with a timestamp greater or equal to 00:00 GMT
				on 12th December, 2020 is the Flare snapshot ledger. This date was chosen in
				commemoration of Martha Coston's Birthday, after whom Flare's testnet is named.
				<XIcon icon="fire-alt" size="lg" className="ml-1 text-danger"/>
				</Callout>
			</Col>
		</Row>
		}
		<Card className="shadow-sm">
			<Card.Body>
				<Card.Title>
					<CardTitleContainer icon="clipboard-check" title="Ledger summary" />
				</Card.Title>
				<Row>
					<Col xs={12} md={8}>
						<Table responsive>
							<thead>
								<tr>
									<th>Ledger index</th>
									<th>
										<LedgerTag ledger_index={ledger.ledger_index} previous next/>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Ledger hash:</td>
									<td className="hash text-muted">
										<Link to={`/ledger/${ledger.ledger_hash}`}>{ledger.ledger_hash}</Link>
									</td>
								</tr>
								<tr>
									<td>Parent hash:</td>
									<td className="hash text-muted">
										<Link to={`/ledger/${ledger.parent_hash}`}>{ledger.parent_hash}</Link>
									</td>
								</tr>
							{ ledger.account_hash &&
								<tr>
									<td>Accounts hash:</td>
									<td className="hash text-muted">{ledger.account_hash}</td>
								</tr>
							}
								<tr>
									<td>Txs hash:</td>
									<td className="hash text-muted">{ledger.transaction_hash}</td>
								</tr>
							</tbody>
						</Table>
					</Col>

					<Col xs={12} md={4}>
						<Table responsive>
							<thead>
								<tr>
									<th colSpan="2">Properties</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Closed on:</td>
									<td className='text-right'>
									{ ledger.close_time_human &&
										<FormattedDate 
											value={ledger.close_time_human} 
											year='numeric' 
											month='short' 
											day='2-digit' 
											hour='2-digit' 
											minute='2-digit' 
											second='2-digit'
											timeZone='UTC'
											timeZoneName='short'
										/>
									}
									</td>
								</tr>
								<tr>
									<td><abbr title="Total XRP available on-ledger">Total coins</abbr>:</td>
									<td className='text-right'>
									{ ledger.total_coins &&
										<Money value={ledger.total_coins} drops />
									}
									</td>
								</tr>
								{ledger?.tx_count >= 0 &&
								<tr>
									<td><abbr title="Transactions included in this ledger">Txs included</abbr>:</td>
									<td className='text-right'>
										<span className='text-monospace'><FormattedNumber value={ledger?.tx_count} /></span>
									</td>
								</tr>
								}
								<tr className="table-warning">
									<td>&Sigma; Fee burned:</td>
									<td className='text-right'>
									{ totalFee &&
										<Money value={totalFee} drops />
									}
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	</div>
	);
}


LedgerInfo.propTypes = {
	ledger: PropTypes.object.isRequired,
	totalFee: PropTypes.number,
	error: PropTypes.object,	
}

export default LedgerInfo;
