import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Image from 'react-bootstrap/Image';
import nexoLogo from '../../assets/images/icons/icon.nexo.com.jpg';

const EarnButton = () => {
    return (
        <OverlayTrigger
            trigger="click"
            key="bottom"
            placement="bottom"
            overlay={
                <Popover id="popover-bottom">
                    <Popover.Title as="h4" className='text-info link-plain'>
                        <Image alt="Nexo logo" src={nexoLogo} height="24" className="mr-1" />
                        <a href="https://api.xrpscan.com/api/v1/campaign/nexoearn1" target="_blank" rel="noopener noreferrer">
                            Nexo: Earn up to 14% annually
                        </a>
                    </Popover.Title>
                    <Popover.Content>
                        <a href="https://api.xrpscan.com/api/v1/campaign/nexoearn1" target="_blank" rel="noopener noreferrer">
                            Build your long-term wealth with industry-leading rates on XRP, BTC, and more. 
                        </a>
                    </Popover.Content>
                    <hr/>
                    <Popover.Title as="h4" className='text-info link-plain'>
                        <Image alt="nexo logo" src={nexoLogo} height="24" className="mr-1" />
                        <a href="https://api.xrpscan.com/api/v1/campaign/nexoborrow1" target="_blank" rel="noopener noreferrer">
                            Nexo: Borrow without selling your crypto
                        </a>
                    </Popover.Title>
                    <Popover.Content>
                        <a href="https://api.xrpscan.com/api/v1/campaign/nexoborrow1" target="_blank" rel="noopener noreferrer">
                            Access a Credit Line from as low as 2.9% annual interest. Get funds within 24 hours.
                        </a>
                    </Popover.Content>
                </Popover>
            }
        >
            <Button variant="info" size="sm" className="dropdown-toggle">Earn Yield and Borrow</Button>
        </OverlayTrigger>
    )
}

export default EarnButton;
